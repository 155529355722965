/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .spanSelectLang{
    color: #EFDEB3 !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .spanSelectLang{ 
    color: #EFDEB3 !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .spanSelectLang{
    color: black !important;
  }
}